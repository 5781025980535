import React from "react";
import SEO from "../common/SEO";

import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import FormTwo from "../component/contact/FormTwo";

const Contact = () => {
  return (
    <>
      <SEO title="Contact" />

      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne title="Contact Us" page="Contact" />

        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-xl-5 col-lg-6">
                <div className="contact-form-box shadow-box mb--30">
                  <h3 className="title">Send us a message</h3>
                  <FormTwo />
                </div>
              </div>
              <div className="col-xl-5 col-lg-6 offset-xl-1">
                <div className="contact-info mb--100 mb_md--30 mt_md--0 mt--150">
                  <h4 className="title">Call Us</h4>
                  {/* <p>
                    Our customer care is open from Mon-Fri, 10:00 am to 6:00 pm
                  </p> */}
                  <h4 className="phone-number">
                    <a href="tel:918111922332">+91 8111 922332</a>
                  </h4>
                </div>
                <div className="contact-info mb--30">
                  <h4 className="title">Mail Us</h4>
                  {/* <p>
                    Our support team will get back to in 48-h during standard
                    business hours.
                  </p> */}
                  <h4 className="phone-number">
                    <a href="mailto:info@merclain.com">info@merclain.com</a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <ul className="list-unstyled shape-group-12">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/shapes/shape-8.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/circle-3.png"}
                alt="Circle"
              />
            </li>
          </ul>
        </div>

        <FooterOne parentClass="pt--150 pt_lg--100 pt_md--80 pt_sm--60" />
      </main>
    </>
  );
};

export default Contact;
