import React, { useState } from "react";
import Alert from "react-bootstrap/Alert";

import { Form, Field } from "react-final-form";

const required = (value) => (value ? undefined : "This field is Required");

async function saveTOGoogleForm(values, url) {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        // Add additional headers if needed
      },
      body: new URLSearchParams(values), // Converts the object to form-urlencoded format
    })
      .then((response) => {
        resolve(true);
      })
      .catch((error) => reject(error));
  });
}

const Result = ({ status, message }) => {
  return (
    <Alert
      variant={status === true ? "success" : "danger"}
      className={status === true ? "success-msg" : "error-msg"}
    >
      {message}
    </Alert>
  );
};

const FormTwo = () => {
  const [loading, setLoading] = useState(false);
  const [submitStat, setSUbmitStat] = useState(null);

  const onSubmit = async (values) => {
    setLoading(true);
    setSUbmitStat(null);

    try {
      const response = await saveTOGoogleForm(
        {
          "entry.1330396612": values.name,
          "entry.749325829": values.email,
          "entry.978015017": values.message,
          "entry.1509517284": values.phone,
        },
        "https://docs.google.com/forms/d/e/1FAIpQLScoy-d-T2uzVSSK2xS6WXLCGYW7ObEW4P21vdr2KcjLBCd4tA/formResponse"
      );

      return response;
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form
          className="axil-contact-form"
          onSubmit={(event) => {
            const promise = handleSubmit(event);
            promise &&
              promise.then((response) => {
                if (response) {
                  setSUbmitStat("true");
                  form.restart();
                } else {
                  setSUbmitStat("false");
                }
                setLoading(false);
              });
            return promise;
          }}
        >
          <Field
            name="name"
            type="text"
            validate={required}
            render={({ input, meta }) => (
              <div className="form-group">
                <label>Name</label>
                <input {...input} placeholder="" className="form-control" />
                {meta.touched && meta.error && (
                  <div className="error-msg py-2">{meta.error}</div>
                )}
              </div>
            )}
          />

          <Field
            name="email"
            type="email"
            placeholder=""
            validate={required}
            render={({ input, meta }) => (
              <div className="form-group">
                <label>Email</label>
                <input {...input} className="form-control" />
                {meta.touched && meta.error && (
                  <div className="error-msg py-2">{meta.error}</div>
                )}
              </div>
            )}
          />

          <Field
            name="phone"
            type="tel"
            placeholder=""
            validate={required}
            render={({ input, meta }) => (
              <div className="form-group">
                <label>Phone</label>
                <input {...input} className="form-control" />
                {meta.touched && meta.error && (
                  <div className="error-msg py-2">{meta.error}</div>
                )}
              </div>
            )}
          />

          <Field
            name="message"
            placeholder="Message"
            validate={required}
            render={({ input, meta }) => (
              <div className="form-group mb--40">
                <label>How can we help you?</label>
                <textarea
                  {...input}
                  rows="4"
                  placeholder="Message"
                  className="form-control"
                />
                {meta.touched && meta.error && (
                  <div className="error-msg py-2">{meta.error}</div>
                )}
              </div>
            )}
          />

          <div className="form-group">
            <button
              type="submit"
              disabled={loading}
              className="axil-btn btn-fill-primary btn-fluid btn-primary"
              name="submit-btn"
            >
              {loading ? "loading..." : "SEND MESSAGE"}
            </button>
          </div>

          <div className="form-group">
            {submitStat === "true" ? (
              <Result
                status={true}
                message={"Your Message has been successfully sent."}
              />
            ) : null}
          </div>

          <div className="form-group">
            {submitStat === "false" ? (
              <Result
                status={false}
                message={"Something went wrong! Please try again."}
              />
            ) : null}
          </div>
        </form>
      )}
    />
  );
};

export default FormTwo;
