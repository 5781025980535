import React from "react";
import SectionTitle from "../../elements/section-title/SectionTitle";
import TestimonialPropOne from "./TestimonialPropOne";

const TestimonialOne = () => {
  return (
    <div className="section section-padding">
      <div className="container">
        <SectionTitle
          subtitle="Testimonial"
          title="Real <span class='heading-font-italics'>Stories</span>, Real <span class='heading-font-italics'>Impact</span>"
          description="Our clients are at the heart of everything we do. We take pride in delivering solutions that not only meet but exceed their expectations. Hear from those who have partnered with us and experienced firsthand how Merclain transforms ideas into digital success."
          textAlignment="heading-left"
          textColor=""
        />
        <div className="row">
          <TestimonialPropOne colSize="col-lg-4" itemShow="3" />
        </div>
      </div>
      <ul className="shape-group-4 list-unstyled">
        <li className="shape-1">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
            alt="Bubble"
          />
        </li>
      </ul>
    </div>
  );
};

export default TestimonialOne;
