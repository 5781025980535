import React from "react";
import FormTwo from "../contact/FormTwo";

const AboutOne = () => {
  return (
    <section className="section section-padding-equal bg-color-light">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-us">
              <div className="section-heading heading-left mb-0">
                <span className="subtitle">About Us</span>
                <h2 className="title mb--40">
                  We’re just a{" "}
                  <span className="heading-font-italics">message</span> away
                  from making your vision a reality
                </h2>
                <p>
                  Your ideas are unique, and we’re here to transform them into
                  exceptional digital experiences. Whether you’re looking to
                  create a standout website, develop a powerful mobile app, or
                  elevate your brand’s presence, we’re ready to listen, advise,
                  and execute.
                </p>
                <p>
                  Don’t wait to get in touch with us today, and let’s turn your
                  vision into a successful, tangible solution. Our team is eager
                  to help you bring your goals to life and take your business to
                  the next level.
                </p>
              </div>
            </div>
          </div>

          <div className="col-xl-5 col-lg-6 offset-xl-1">
            <div className="contact-form-box">
              <h3 className="title">Contact Us</h3>
              <FormTwo />
            </div>
          </div>
        </div>
      </div>
      <ul className="shape-group-6 list-unstyled">
        <li className="shape shape-1">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-7.png"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-2">
          <img
            src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
            alt="line"
          />
        </li>
        <li className="shape shape-3">
          <img
            src={process.env.PUBLIC_URL + "/images/others/line-5.png"}
            alt="line"
          />
        </li>
      </ul>
    </section>
  );
};

export default AboutOne;
