import React from "react";
import { useParams } from "react-router-dom";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import BcrumbBannerTwo from "../elements/breadcrumb/BcrumbBannerTwo";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import { slugify } from "../utils";
import ProjectData from "../data/project/ProjectData.json";

import Accordion from "react-bootstrap/Accordion";
import { FaCompress } from "react-icons/fa";

import SEO from "../common/SEO";

const allProjectData = ProjectData;

const ProjectDetails = () => {
  const params = useParams();
  const projectSlug = params.slug;

  const getProjectData = allProjectData.filter(
    (data) => slugify(data.title) === projectSlug
  );
  const detailsProject = getProjectData[0];

  return (
    <>
      <SEO title={`${detailsProject.title} - Project`} />

      <main className="main-wrapper">
        <HeaderOne />
        <BcrumbBannerTwo
          title={detailsProject.title}
          paragraph={detailsProject.excerpt}
          mainThumb={detailsProject.image}
        />
        <section className="section-padding single-portfolio-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <div className="section-heading heading-left mb-0">
                  <span className="subtitle">
                    {detailsProject.category.map((cat, i) => (
                      <span key={i}>{cat}</span>
                    ))}
                  </span>
                  <h3 className="title">{detailsProject.title}</h3>
                </div>
                {detailsProject.body.map((para, index) => (
                  <p key={index} dangerouslySetInnerHTML={{ __html: para }}></p>
                ))}
                {/* <Link to="#" className="axil-btn btn-fill-primary">
                  Get it Now
                </Link> */}
              </div>
              <div className="col-lg-6 offset-xl-1">
                <div className="why-choose-us">
                  <div className="section-heading heading-left">
                    <h3 className="title">We delivered</h3>
                    <p>
                      {detailsProject.deliveries &&
                      detailsProject.deliveries.text
                        ? detailsProject.deliveries.text
                        : ""}
                    </p>
                  </div>
                  {detailsProject.deliveries &&
                    detailsProject.deliveries.tabs &&
                    detailsProject.deliveries.tabs.length > 0 && (
                      <Accordion defaultActiveKey="1">
                        {detailsProject.deliveries.tabs.map((item, index) => (
                          <Accordion.Item eventKey={index} key={index}>
                            <Accordion.Header>
                              <FaCompress /> {item.title}
                            </Accordion.Header>
                            <Accordion.Body>{item.content}</Accordion.Body>
                          </Accordion.Item>
                        ))}
                      </Accordion>
                    )}
                </div>
              </div>
            </div>
          </div>
        </section>

        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default ProjectDetails;
