import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import BcrumbBannerOne from "../elements/breadcrumb/BcrumbBannerOne";

import SEO from "../common/SEO";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import ProjectOne from "../component/project/ProjectOne";

const ProjectGridOne = () => {
  return (
    <>
      <SEO title="Projects" />

      <main className="main-wrapper">
        <HeaderOne />
        <BcrumbBannerOne
          title="Our Projects"
          paragraph="Explore some of our diverse range of projects showcasing innovation, creativity, and impactful solutions tailored to meet the evolving needs of businesses and individuals"
          styleClass="banner-project"
          mainThumb="/images/banner/projects-banner.png"
        />
        <ProjectOne colSize="col-xl-4 col-md-6" columnGap="row-15" />
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default ProjectGridOne;
