import React from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";
import { IoMdHappy } from "react-icons/io";
import { GrProjects } from "react-icons/gr";
import { BsPersonWorkspace } from "react-icons/bs";
import { MdWorkspacePremium } from "react-icons/md";

const Data = [
  {
    id: 1,
    light_icon: "/images/icon/icon-7.png",
    dark_icon: "/images/icon/icon-10.png",
    Icon: <GrProjects />,
    countNum: 80,
    text: "Completed Projects",
  },
  {
    id: 2,
    Icon: <IoMdHappy />,
    light_icon: "/images/icon/icon-8.png",
    dark_icon: "/images/icon/icon-11.png",
    countNum: 100,
    text: "Happy Customers",
  },
  {
    id: 3,
    Icon: <BsPersonWorkspace />,
    light_icon: "/images/icon/icon-9.png",
    dark_icon: "/images/icon/icon-12.png",
    countNum: 10,
    text: "Years of Experience",
  },
  {
    id: 4,
    Icon: <MdWorkspacePremium />,
    light_icon: "/images/icon/icon-11.png",
    dark_icon: "/images/icon/icon-11.png",
    countNum: 50,
    text: "Proud Recognition",
  },
];

const CounterUp = ({ colSize, layoutStyle, evenTopMargin }) => {
  return (
    <>
      {Data.map((data) => (
        <div
          className={`${colSize} ${data.id % 2 === 0 ? evenTopMargin : ""}`}
          key={data.id}
        >
          <div className={`counterup-progress ${layoutStyle}`}>
            {data.Icon && data.Icon}

            <div className="count-number h2">
              <TrackVisibility once>
                {({ isVisible }) => (
                  <span className="number count">
                    {isVisible ? (
                      <CountUp end={data.countNum} duration={1} />
                    ) : null}
                  </span>
                )}
              </TrackVisibility>
              <span className="symbol">+</span>
            </div>
            <h6 className="title">{data.text}</h6>
          </div>
        </div>
      ))}
    </>
  );
};

export default CounterUp;
