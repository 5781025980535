import React from "react";
import CounterUp from "../../component/counterup/CounterUp";

const CounterUpOne = () => {
  return (
    <div className="section section-padding bg-color-dark">
      <div className="container">
        <div className="row">
          <CounterUp colSize="col-lg-3 col-6" layoutStyle="" evenTopMargin="" />
        </div>
      </div>
    </div>
  );
};

export default CounterUpOne;
