import React from "react";
import SEO from "../common/SEO";

import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import Form from "../component/careers/Form";

const Careers = () => {
  return (
    <>
      <SEO title="Careers" />

      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne title="Careers" page="Careers" />

        <div className="section">
          <div className="container">
            <div className="contact-form-box shadow-box mb--30 career-form-box">
              <h3 className="title">Join Our Team</h3>
              <p>Fill Out the Form Below to Start Your Journey with Us</p>
              <Form />
            </div>
          </div>

          <ul className="list-unstyled shape-group-12">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/shapes/shape-8.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/circle-3.png"}
                alt="Circle"
              />
            </li>
          </ul>
        </div>

        <FooterOne parentClass="pt--150 pt_lg--100 pt_md--80 pt_sm--60" />
      </main>
    </>
  );
};

export default Careers;
