import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import BcrumbBannerOne from "../elements/breadcrumb/BcrumbBannerOne";

import SEO from "../common/SEO";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import SectionTitle from "../elements/section-title/SectionTitle";
import ServicePropOne from "../component/service/ServicePropOne";
import CounterUpTwo from "../component/counterup/CounterUpTwo";

const ServiceTwo = () => {
  return (
    <>
      <SEO title="Services" />

      <main className="main-wrapper">
        <HeaderOne />

        <BcrumbBannerOne
          title="Best <span class='heading-font-italics'>solutions</span> for your business"
          paragraph="We offer a comprehensive suite of services designed to empower your business and drive your digital success.
                "
          styleClass=""
          mainThumb="/images/banner/banner-services.png"
        />
        <CounterUpTwo />
        <div className="section section-padding bg-color-light">
          <div className="container">
            <SectionTitle
              subtitle=""
              title="<span class='heading-font-italics'>Services</span> we can <br> help you with"
              description=""
              textAlignment="heading-left"
              textColor=""
            />
            <div className="row">
              <ServicePropOne
                colSize="col-lg-4 col-md-6"
                serviceStyle="service-style-2"
                itemShow="8"
                marginTop="no"
              />
            </div>
          </div>
        </div>

        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default ServiceTwo;
