import React from "react";
import { useParams } from "react-router-dom";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import BcrumbBannerOne from "../elements/breadcrumb/BcrumbBannerOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import SectionTitle from "../elements/section-title/SectionTitle";
// import ProjectPropOne from "../component/project/itemProp/ProjectPropOne";
import { slugify } from "../utils";
import ServiceData from "../data/service/ServiceMain.json";
// import ProjectData from "../data/project/ProjectData.json";

import FormTwo from "../component/contact/FormTwo";
import Accordion from "react-bootstrap/Accordion";
import * as Icons from "react-icons/fa";

import SEO from "../common/SEO";
import Tilty from "react-tilty";

const allServiceData = ServiceData;
// const getProjectData = ProjectData;

const ServiceDetails = () => {
  const params = useParams();
  const serviceSlug = params.slug;

  const getServiceData = allServiceData.filter(
    (data) => slugify(data.title) === serviceSlug
  );
  const detailsService = getServiceData[0];

  return (
    <>
      <SEO title="Service Details" />

      <main className="main-wrapper">
        <HeaderOne />
        <BcrumbBannerOne
          title={detailsService.title}
          paragraph={detailsService.description}
          styleClass=""
          mainThumb={
            detailsService.banner
              ? detailsService.banner
              : "/images/banner/banner-services.png"
          }
        />
        <div className="section-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="why-choose-us">
                  <div className="section-heading heading-left">
                    {/* <span className="subtitle">About Us</span> */}
                    <h3 className="title">{detailsService.why.title}</h3>
                    <p>{detailsService.why.text}</p>
                  </div>
                  {detailsService.why.tabs.length > 0 && (
                    <Accordion defaultActiveKey="1">
                      {detailsService.why.tabs.map((item, index) => (
                        <Accordion.Item eventKey={`${index + 1}`} key={index}>
                          <Accordion.Header>
                            <Icon name={item.icon}></Icon>
                            {item.title}
                          </Accordion.Header>
                          <Accordion.Body>{item.content}</Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  )}
                </div>
              </div>
              <div className="col-xl-5 col-lg-6 offset-xl-1">
                <div className="contact-form-box shadow-box mb--30">
                  <h3 className="title">Get a quote now</h3>
                  <FormTwo />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section section-padding bg-color-light pb--70">
          <SectionTitle
            subtitle={detailsService.process.sectionSub}
            title={detailsService.process.sectionTitle}
            description={detailsService.process.para}
            textAlignment=""
            textColor="mb--90"
          />
          <div className="container">
            {detailsService.process.steps.map((data, index) => (
              <div
                key={index}
                className={`process-work ${
                  index % 2 === 1 ? "content-reverse" : ""
                }`}
              >
                {data.thumb && (
                  <Tilty perspective={2000}>
                    <div className="thumbnail">
                      <img
                        src={process.env.PUBLIC_URL + data.thumb}
                        alt="Thumbnail"
                      />
                    </div>
                  </Tilty>
                )}

                <div className="content">
                  <span className="subtitle">{data.subtitle}</span>
                  <h3 className="title">{data.title}</h3>
                  <p>{data.paragraph}</p>
                </div>
              </div>
            ))}
          </div>
          <ul className="shape-group-17 list-unstyled">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-24.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-23.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
                alt="Line"
              />
            </li>
            <li className="shape shape-4">
              <img
                src={process.env.PUBLIC_URL + "/images/others/line-5.png"}
                alt="Line"
              />
            </li>
            <li className="shape shape-5">
              <img
                src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
                alt="Line"
              />
            </li>
            <li className="shape shape-6">
              <img
                src={process.env.PUBLIC_URL + "/images/others/line-5.png"}
                alt="Line"
              />
            </li>
          </ul>
        </div>

        <div className="section section-padding">
          {/*   <div className="container">
            <SectionTitle
              subtitle="Our Project"
              title="Featured Designs"
              description=""
              textAlignment=""
              textColor=""
            />
            <div className="row row-35">
              {getProjectData.slice(0, 2).map((data) => (
                <div className="col-md-6" key={data.id}>
                  <ProjectPropOne projectStyle="" portfolio={data} />
                </div>
              ))}
            </div>
          </div> */}

          <ul className="shape-group-16 list-unstyled">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/circle-2.png"}
                alt="circle"
              />
            </li>
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
                alt="Line"
              />
            </li>
          </ul>
        </div>
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

const Icon = ({ name, size = 24, color = "black" }) => {
  const IconComponent = Icons[name];

  if (!IconComponent) {
    console.warn(`Icon "${name}" not found`);
    return null;
  }

  return <IconComponent size={size} color={color} />;
};

export default ServiceDetails;
