import React from "react";

const Data = [
  {
    image: "/images/brand/health-domain.png",
  },
  {
    image: "/images/brand/first-deals.png",
  },
  {
    image: "/images/brand/wowlyx.png",
  },
  {
    image: "/images/brand/tapnow.png",
  },
  {
    image: "/images/brand/edurupt.png",
  },

  {
    image: "/images/brand/shivani.png",
  },

  {
    image: "/images/brand/dish-media.png",
  },
  {
    image: "/images/brand/splash.png",
  },
];

const BrandItem = () => {
  return (
    <>
      {Data.map((data, index) => (
        <div className="col-lg-3 col-6" key={index}>
          <div
            className="brand-grid"
            style={{ backgroundColor: data.background || "" }}
          >
            <img src={process.env.PUBLIC_URL + data.image} alt="Brand" />
          </div>
        </div>
      ))}
    </>
  );
};

export default BrandItem;
