import React from "react";
import { Link } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import Nav from "./Nav";
import { FiPhoneCall } from "react-icons/fi";

const MobileMenu = ({ MobileHandler }) => {
  return (
    <div className="mobilemenu-popup">
      <div className="mobilemenu-inner">
        <div className="mobilemenu-header">
          <div className="mobile-nav-logo">
            <Link to={process.env.PUBLIC_URL + "/"}>
              <img
                className="light-mode"
                src={process.env.PUBLIC_URL + "/images/logo.svg"}
                alt="Site Logo"
              />
              <img
                className="dark-mode"
                src={process.env.PUBLIC_URL + "/images/logo.svg"}
                alt="Site Logo"
              />
            </Link>
          </div>
          <button className="mobile-menu-close" onClick={MobileHandler}>
            <FaTimes />
          </button>
        </div>
        <div className="mobilemenu-body">
          <Nav />
          <div className="call-us-btn pt-4">
            <div className="icon-holder">
              <FiPhoneCall />
            </div>
            <div className="content">
              <span>Call us</span>
              <a
                href="tel:918111922332"
                target="_blank"
                rel="noopener noreferrer"
              >
                +91 8111 922332
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
