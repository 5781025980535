import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const SEO = ({ title }) => {
  return (
    <Helmet>
      <title> {title ? `${title} | ` : ""}Merclain Technologies</title>
      <meta
        name="title"
        content={
          title ? `${title} | Merclain Technologies` : "Merclain Technologies"
        }
      />
      <meta name="description" content="Bridging Vision & Technology" />
    </Helmet>
  );
};
SEO.propTypes = {
  title: PropTypes.string,
};

export default SEO;
