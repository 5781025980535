import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import SEO from "../common/SEO";
import BcrumbBannerOne from "../elements/breadcrumb/BcrumbBannerOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
// import ProcessOne from "../component/process/ProcessOne";
import AboutThree from "../component/about/AboutThree";
import AboutFour from "../component/about/AboutFour";
// import AboutFive from "../component/about/AboutFive";

const AboutUs = () => {
  return (
    <>
      <SEO title="About us" />
      <main className="main-wrapper">
        <HeaderOne />
        <BcrumbBannerOne
          title="<span class='heading-font-italics'>Impactful</span> Digital<br>Experiences"
          paragraph="We believe that digital experiences should do more than just function, they should inspire, engage, and drive results. We combine innovative design with powerful technology to craft solutions that resonate with users and create lasting impressions."
          styleClass="thumbnail-4 banner-about"
          mainThumb="/images/banner/about-us-banner.png"
        />
        <AboutFour />
        <AboutThree />
        {/* <AboutFive /> */}
        {/* <ProcessOne /> */}
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default AboutUs;
