import React from "react";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";

const AboutFour = () => {
  return (
    <div className="section section-padding case-study-featured-area">
      <div className="container">
        <div className="row">
          <div className="col-xl-7 col-lg-6">
            <div className="case-study-featured-thumb text-start">
              <img
                src={
                  process.env.PUBLIC_URL + "/images/banner/about-us-phones.png"
                }
                alt="travel"
              />
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="case-study-featured">
              <div className="section-heading heading-left">
                <span className="subtitle">Who we are</span>
                <h2 className="title">
                  Building Digital{" "}
                  <span className="heading-font-italics">Solutions</span> for
                  Visionaries
                </h2>
                <p>
                  Merclain, we craft & innovative digital solutions for
                  businesses that dare to dream big and make a difference. Since
                  2015, we’ve been partnering with forward-thinkers to design
                  and develop transformative digital experiences that drive
                  real-world impact.
                </p>
                <p>
                  From Branding to Web Development, UI/UX design to Mobile App
                  solutions & Digital Marketing our expertise fuels growth and
                  innovation for clients across industries.
                </p>
                <Link
                  to="/contact"
                  className="axil-btn btn-fill-primary btn-large"
                >
                  Contact Us
                </Link>
              </div>
              <div className="case-study-counterup">
                <div className="single-counterup">
                  <h2 className="count-number">
                    <TrackVisibility once>
                      {({ isVisible }) => (
                        <span className="number count">
                          {isVisible ? <CountUp end="10" duration={1} /> : null}
                        </span>
                      )}
                    </TrackVisibility>
                    <span className="symbol">+</span>
                  </h2>
                  <span className="counter-title">Years on the market</span>
                </div>
                <div className="single-counterup">
                  <h2 className="count-number">
                    <TrackVisibility once>
                      {({ isVisible }) => (
                        <span className="number count">
                          {isVisible ? <CountUp end="80" duration={1} /> : null}
                        </span>
                      )}
                    </TrackVisibility>
                    <span className="symbol">+</span>
                  </h2>
                  <span className="counter-title">
                    Projects delivered so far
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFour;
