import React, { useState } from "react";
import Alert from "react-bootstrap/Alert";

import { Form, Field } from "react-final-form";

import jobOpenings from "../../data/careers/jobOpenings.json";

const AllData = jobOpenings;

const required = (value) => (value ? undefined : "This field is Required");

const validatePhoneNumber = (value) => {
  if (!value) {
    return "Phone number is required";
  }
  if (!/^\d+$/.test(value)) {
    return "Please add a valid number";
  }
  // if (value.length < 10) {
  //   return "Phone number must be at least 10 digits";
  // }
  return undefined; // Valid input
};

const validateFile = (file) => {
  if (!file) return "Resume is required";
  const allowedTypes = [
    "application/pdf",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];
  if (!allowedTypes.includes(file.type)) {
    return "Only PDF or DOCX files are allowed";
  }
  if (file.size > 5 * 1024 * 1024) {
    return "Resume size must be under 5MB";
  }
  return undefined;
};

const FileInputField = ({ name, label, accept, validate }) => {
  const handleFileChange = (event, input) => {
    const file = event.target.files[0];
    input.onChange(file); // Pass the file object to the form
  };

  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => (
        <div className="form-group mb--40">
          <label>
            {label}
            <input
              type="file"
              accept={accept}
              onChange={(e) => handleFileChange(e, input)}
              className="form-control"
            />
          </label>
          {meta.touched && meta.error && (
            <div className="error-msg py-2">{meta.error}</div>
          )}
        </div>
      )}
    </Field>
  );
};

const uploadResume = (file) => {
  return new Promise((resolve, reject) => {
    let formData = new FormData();
    formData.append("file", file);

    fetch(`https://merclain.com/file-uploads/upload.php`, {
      method: "POST",
      header: {
        "Content-Type": "multipart/form-data",
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          resolve(result.data.file_url);
        } else {
          throw new Error("Error:", result.message);
        }
      })
      .catch((error) => {
        console.log(error);
        reject({ msg: "Error in file upload! Please try again." });
      });
  });
};

async function saveTOGoogleForm(values, url) {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        // Add additional headers if needed
      },
      body: new URLSearchParams(values), // Converts the object to form-urlencoded format
    })
      .then((response) => {
        resolve(true);
      })
      .catch((error) => reject(error));
  });
}

const Result = ({ status, message }) => {
  return (
    <Alert
      variant={status === true ? "success" : "danger"}
      className={status === true ? "success-msg" : "error-msg"}
    >
      {message}
    </Alert>
  );
};

const FormTwo = () => {
  const [loading, setLoading] = useState(false);
  const [submitStat, setSUbmitStat] = useState(null);

  const onSubmit = async (values) => {
    setLoading(true);
    setSUbmitStat(null);

    try {
      const fileUrl = await uploadResume(values.resume);
      values = { ...values, fileUrl };

      const response = await saveTOGoogleForm(
        {
          "entry.1330396612": values.name,
          "entry.1509517284": values.phone,
          "entry.749325829": values.email,
          "entry.464888258": values.position,
          "entry.1890805463": values.fileUrl,
          "entry.978015017": values.comments || "",
        },
        "https://docs.google.com/forms/d/e/1FAIpQLSc_08PwOneicNKvMWG_Af9LRZBh0wUzPKEBtECRUtPBTipCRw/formResponse"
      );

      return response;
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form
          className="axil-contact-form"
          onSubmit={(event) => {
            const promise = handleSubmit(event);
            promise &&
              promise.then((response) => {
                if (response) {
                  setSUbmitStat("true");
                  form.restart();
                } else {
                  setSUbmitStat("false");
                }
                setLoading(false);
              });
            return promise;
          }}
        >
          <Field
            name="name"
            type="text"
            validate={required}
            render={({ input, meta }) => (
              <div className="form-group">
                <label>Name</label>
                <input {...input} placeholder="" className="form-control" />
                {meta.touched && meta.error && (
                  <div className="error-msg py-2">{meta.error}</div>
                )}
              </div>
            )}
          />

          <Field
            name="email"
            type="email"
            placeholder=""
            validate={required}
            render={({ input, meta }) => (
              <div className="form-group">
                <label>Email</label>
                <input {...input} className="form-control" />
                {meta.touched && meta.error && (
                  <div className="error-msg py-2">{meta.error}</div>
                )}
              </div>
            )}
          />

          <Field
            name="phone"
            type="tel"
            placeholder=""
            validate={validatePhoneNumber}
            render={({ input, meta }) => (
              <div className="form-group">
                <label>Phone</label>
                <input {...input} className="form-control" />
                {meta.touched && meta.error && (
                  <div className="error-msg py-2">{meta.error}</div>
                )}
              </div>
            )}
          />

          <Field
            name="position"
            type="tel"
            placeholder=""
            validate={required}
            render={({ input, meta }) => (
              <div className="form-group">
                <label>Applying For</label>
                <select {...input} className="form-control form-select-input">
                  <option value="" disabled>
                    Select
                  </option>
                  {AllData.map((data, index) => {
                    return (
                      <option value={data.value} key={index}>
                        {data.label}
                      </option>
                    );
                  })}
                  <option value="Open Role">Open Role</option>
                </select>

                {meta.touched && meta.error && (
                  <div className="error-msg">{meta.error}</div>
                )}
              </div>
            )}
          />

          <FileInputField
            name="resume"
            label="Upload Your Resume:"
            accept=".pdf,.docx"
            validate={validateFile}
          />

          <Field
            name="comments"
            placeholder="Comments"
            render={({ input, meta }) => (
              <div className="form-group mb--40">
                <label>Comments</label>
                <textarea
                  {...input}
                  rows="4"
                  placeholder=""
                  className="form-control"
                />
                {meta.touched && meta.error && (
                  <div className="error-msg py-2">{meta.error}</div>
                )}
              </div>
            )}
          />

          <div className="form-group">
            <button
              type="submit"
              disabled={loading}
              className="axil-btn btn-fill-primary btn-fluid btn-primary"
              name="submit-btn"
            >
              {loading ? "loading..." : "SUBMIT"}
            </button>
          </div>

          <div className="form-group">
            {submitStat === "true" ? (
              <Result
                status={true}
                message={"Your details has been successfully sent."}
              />
            ) : null}
          </div>

          <div className="form-group">
            {submitStat === "false" ? (
              <Result
                status={false}
                message={"Something went wrong! Please try again."}
              />
            ) : null}
          </div>
        </form>
      )}
    />
  );
};

export default FormTwo;
