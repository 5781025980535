import React, { useRef, useState } from "react";

import Alert from "react-bootstrap/Alert";
import { FaEnvelopeOpen } from "react-icons/fa";

async function saveTOGoogleForm(values, url) {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        // Add additional headers if needed
      },
      body: new URLSearchParams(values), // Converts the object to form-urlencoded format
    })
      .then((response) => {
        resolve(true);
      })
      .catch((error) => reject(error));
  });
}

const Result = ({ status, message }) => {
  return (
    <Alert
      variant={status === true ? "success" : "danger"}
      className={status === true ? "success-msg" : "error-msg"}
    >
      {message}
    </Alert>
  );
};

const Newsletter = () => {
  const form = useRef();

  const [submitStat, setSUbmitStat] = useState(null);
  const [loading, setLoading] = useState(false);

  const sendEmail = async (e) => {
    try {
      e.preventDefault();
      setSUbmitStat(null);
      setLoading(true);

      if (
        e.target.elements.email.value &&
        e.target.elements.email.value !== ""
      ) {
        const response = await saveTOGoogleForm(
          {
            "entry.1291344887": e.target.elements.email.value,
          },
          "https://docs.google.com/forms/d/e/1FAIpQLScwL-FmI8fZ_CtLPfY6ZZGDKrX49eJRyLpXKie8LpI41MwI7Q/formResponse"
        );

        if (response) {
          form.current.reset();
          setSUbmitStat("true");
          setLoading(false);
        } else {
          throw new Error();
        }
      } else {
        throw new Error();
      }
      setLoading(false);
    } catch (error) {
      setSUbmitStat("false");
      setLoading(false);
    }
  };

  setTimeout(() => {
    setSUbmitStat(null);
  }, 5000);

  return (
    <form ref={form} onSubmit={sendEmail}>
      <div className="input-group">
        <span className="mail-icon">
          <FaEnvelopeOpen />{" "}
        </span>
        <input
          type="email"
          name="email"
          className="form-control"
          placeholder="Email address"
          required
        />

        <button
          className="axil-btn btn-fill-primary btn-fluid btn-primary subscribe-btn"
          type="submit"
          disabled={loading}
        >
          Subscribe
        </button>
      </div>
      <div>
        {submitStat === "true" ? (
          <Result status={true} message={"Thank you for subscribing."} />
        ) : null}
      </div>

      <div>
        {submitStat === "false" ? (
          <Result
            status={false}
            message={"Something went wrong! Please try again."}
          />
        ) : null}
      </div>
    </form>
  );
};

export default Newsletter;
